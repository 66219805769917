import { render, staticRenderFns } from "./DaysSelector.vue?vue&type=template&id=18e019f1&scoped=true&"
import script from "./DaysSelector.vue?vue&type=script&lang=ts&"
export * from "./DaysSelector.vue?vue&type=script&lang=ts&"
import style0 from "./DaysSelector.vue?vue&type=style&index=0&id=18e019f1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e019f1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle})
