
















// Vue
import Vue, { PropType } from "vue";
// Constants
import { allDaysOfWeek, DayOfWeek, dayOfWeekDisplay } from "@/core/_constants/daysOfWeek.constant";

export default Vue.extend({
  name: "DaysSelector",

  props: {
    value: {
      type: Array as PropType<DayOfWeek[]>,
      default: () => []
    }
  },

  data() {
    return {
      days: allDaysOfWeek,
      dayOfWeekDisplay: dayOfWeekDisplay,

      localValue: this.value ?? []
    };
  },

  watch: {
    value: function (newValue) {
      this.localValue = newValue;
    }
  },

  methods: {
    handleValueChange() {
      this.$emit("input", this.localValue);
    }
  }
});
